import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { types, floors, days } from './consts'
import { RootState } from '../store'
import { getCurrentDayKey } from '../../helper/DateFornat'
import { loadState } from '../../helper/LocalStorage'

type FilterState = {
  floors: string[]
  types: string[]
  days: string
  search: string
  info: string
  floorInfo: string
  openedFilter: string | null
}

const savedState: RootState | undefined = loadState()

export const getOriginalFilterState = (): FilterState => {
  const state: FilterState = {
    floors: [],
    types: [],
    days: getCurrentDayKey(),
    search: '',
    info: '',
    floorInfo: '',
    openedFilter: null,
  }

  if (savedState?.filter.types) {
    state.types = savedState?.filter.types
  }
  if (savedState?.filter.floors) {
    state.floors = savedState?.filter.floors
  }

  return state
}

const initialState = getOriginalFilterState()

export type ChangeFilterPayload = { filterId: string; value: string }

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    openFilter: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        openedFilter: action.payload,
      }
    },
    closeFilter: (state) => {
      return {
        ...state,
        openedFilter: null,
      }
    },

    changeFilter: (state, action: PayloadAction<ChangeFilterPayload>) => {
      const { filterId, value } = action.payload

      // console.log('###############', value)

      let filter = state[filterId]

      if (
        filterId === 'days' ||
        filterId === 'search' ||
        filterId === 'info' ||
        filterId === 'floorInfo'
      ) {
        filter = value
      } else {
        // remove value
        if (R.includes(value)(filter)) {
          filter = R.reject((filterValue) => filterValue === value)(filter)
        }
        // or add value
        else {
          filter = [...filter, value]
        }
      }

      return {
        ...state,
        [filterId]: filter,
      }
    },

    resetFilter: (state, action) => {
      const filterId = action.payload

      console.log('############### RESET_FILTER filterId: ', filterId)
      if (filterId === 'search') {
        return {
          ...state,
          [filterId]: '',
        }
      }

      return {
        ...state,
        [filterId]: [],
      }
    },

    /**
     * This function removes any values from the filter that are not in the list of possible values
     */
    checkSavedFilterValues: (state) => {
      const savedFloors = R.filter((floor) => R.includes(floor, floors))(
        state.floors,
      )
      const savedTypes = R.filter((type) => R.includes(type, types))(
        state.types,
      )
      return {
        ...state,
        floors: savedFloors,
        types: savedTypes,
      }
    },
  },
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  openFilter,
  changeFilter,
  checkSavedFilterValues,
  closeFilter,
  resetFilter,
} = filterSlice.actions

// Export the slice reducer as the default export
export default filterSlice.reducer

// export const getFilters = ns.getState

// Other code such as selectors can use the imported `RootState` type
export const getFilters = (state: RootState) => state.filter
export const getDay = (state: RootState) => getFilters(state).days
export const getSearch = (state: RootState) => getFilters(state).search

export const hasFilters = (state: RootState) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { types, floors } = state.filter
  return types.length > 0 || floors.length > 0
}

export const getNextDay = createSelector(getDay, (day) => {
  console.log('days 2: ', days)

  const index = days.indexOf(day)
  // debugger
  const nextIndex = index + 1
  return days[nextIndex]
})

export const getPrevDay = createSelector(getDay, (day) => {
  const index = days.indexOf(day)
  const prevIndex = index - 1
  return days[prevIndex]
})
