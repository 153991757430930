import styled from 'styled-components'
import { device } from '../device'
import colors, { space } from './colors'

type Props = {
  isExtra?: boolean
  active?: boolean
  isFilter?: boolean
  white?: boolean
  flex?: number | string
  mr?: number
  ml?: number
  mt?: number
  mb?: number
}

export const Button = styled.button<Props>`
  /* Adapt the colours based on primary prop */
  background: ${(props) => {
    if (props.isExtra) {
      if (props.active) {
        return colors.highlight
      }
      return colors.backgroundBlack
    }

    if (props.active) {
      return colors.white
    }
    if (props.isFilter) {
      return colors.primary
    }

    if (props.white) {
      return colors.textInverted
    }
    return colors.secondary
  }};
  color: ${(props) =>
    props.white || props.active ? colors.text : colors.textInverted};
  font-size: 16px;
  padding: 13px 1rem 11px;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  user-select: none;
  border: none;
  &:active {
    background-color: ${colors.highlight};
  }
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border-radius: 32px;
  font-family: 'Inter-bold';
  margin-right: ${(props) => (props.mr ? space[props.mr] : 0)}px;
  margin-left: ${(props) => (props.ml ? space[props.ml] : 0)}px;
  margin-top: ${(props) => (props.mt ? space[props.mt] : 0)}px;
  margin-bottom: ${(props) => (props.mb ? space[props.mb] : 0)}px;
`

export const SmallButton = styled(Button)`
  padding: 0.5rem 0.8rem;
  flex: none;
  pointer-events: auto;
`

export const ExtraButton = styled(Button)`
  background: ${(props) => {
    if (props.active) {
      return colors.backgroundBlack
    }
    return colors.secondary
  }};

  &:active {
    background-color: ${colors.primary};
    color: ${colors.textInverted};
  }

  border-radius: 0;
  font-family: 'Inter-bold';
  padding: 8px 0px;
  font-size: 20px;

  @media ${device.mobileM} {
    padding: 12px 0px;
    font-size: 24px;
  }
`
