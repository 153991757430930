import LanguageKeys from './de'

export class LanguageKeysEn extends LanguageKeys {
  public daysEn = {
    Mi: 'We',
    Do: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    So: 'Su',
    Mo: 'Mo',
  }
  public typesEn = {
    Kino: 'Cinema',
  }

  public WELCOME_HEADLINE = 'Welcome to Fusion 2024'
  public WELCOME_MESSAGE =
    'This is our mobile timetable that’s offline available. You just have to do the following steps: <br /><br />Click "Install App" when you see it in the browser. If you dont see it check the browser menu.<br /><br />Now you can access the time table directly from your home screen and once it has been downloaded this works offline as well.'
  public WELCOME_MESSAGE_IOS =
    'This is our mobile timetable that’s offline available. You just have to do the following steps:<br /><br />Open this page in Safari and select the share menu and tap on "add to home screen".<br /><br />Now you can access the time table directly from your home screen and once it has been downloaded this works offline as well.'
  public WELCOME_MESSAGE_NOT_RELEASED =
    'The program is not released yet. Come back later.'

  public INSTALL_PROMT_HEADLINE = 'Offline available'
  public INSTALL_PROMT_MESSAGE =
    'This timetable also works offline. Install it on your phone and use it like an app.'
  public INSTALL_MANUALLY_HEADLINE = 'Offline Usage'
  public INSTALL_MANUALLY_MESSAGE =
    'This timetable also works offline and here\'s how:<br /><br />- Open in Chrome<br />- Open the browser menu<br />- Click <b>"Install App"</b><br /><br /><br />Once clicked, an icon will appear on your home screen, behind which the timetable is located. Then start the app once, making it usable offline.'
  public INSTALL_MANUALLY_MESSAGE_IOS =
    'This timetable also works offline and here\'s how:<br /><br />Open the page in Safari, click the menu, and select "Add to Home Screen".<br /><br />Once clicked, an icon will appear on your home screen, behind which the timetable is located. Then start the app once, making it usable offline.'

  public WELCOME_BUTTON_START = 'Start'
  public NEXT = 'Next'
  public PASSWORD_HEADLINE = 'Passwort required!'
  public PASSWORD_MESSAGE = 'Please enter the password here.'
  public PASSWORD_INPUT_PLACEHOLDER = 'Enter password here'

  public TENT_TITLE = 'Where is my Tent?'
  public TENT_MESSAGE =
    'Drag the tent icon to the location on the map where you are camping and lock it with the Lock Button.'
  public TENT_DRAG_ME = 'Drag me'
  public TENT_LOCK_ME = 'Lock me'

  public EMERGENCY_HEADLINE_1 = 'Emergency?'
  public EMERGENCY_HEADLINE_2 = ' '
  public EMERGENCY_HEADLINE_3 = ' '
  public EMERGENCY_MESSAGE =
    'Security and medical assistance are available 24/7. If you see something STEP IN.'
  public EMERGENCY_NUMBER = '+49 39833 / 2740400'

  public SPECIAL_POSTER_HEADLINE = '25th Fusion Festival anniversary poster'
  public SPECIAL_POSTER_TEXT =
    'For a donation from Sunday. At all rubbish stations and ZOB Bassliner. The donation will go to Medico International Nothilfe Gaza.'

  public LOADING = 'load timetable...'

  public BUTTON_OK = 'OK'

  public BUTTON_TIMETABLE = 'Timetable'
  public BUTTON_FAVORITES = 'Favorites'
  public BUTTON_MAP = 'Map'
  public BUTTON_DEPARTURE = 'Abreise'
  public BUTTON_FILTER_TIMETABLE = 'Filter timetable'
  public BUTTON_FILTER_FAVORITES = 'Filter favorites'
  public BUTTON_UNDERSTOOD = 'Ok'

  public TITLE_PAGE_FILTER = 'Filter program'

  public TITLE_PAGE_DEPARTURE = 'Latest infos from ZOB'
  public PAGE_DEPARTURE_NEUSTRELITZ = 'Shuttle Neustrelitz'
  public PAGE_DEPARTURE_BASSLINER = 'Bassliner'

  public DAY = (day: string) => {
    const dayStr: string = (this.daysEn as any)[day]
    return dayStr || day
  }
  public TYPE = (type: string) => {
    const typeStr: string = (this.typesEn as any)[type]
    return typeStr || type
  }

  // public DAY = (day: string) => daysEn[day] || day
  // public TYPE = (type: string) => type

  public OCLOCK = "o'clock"

  public FILTER_WHEN = 'WHEN'
  public FILTER_WHAT = 'WHAT'
  public FILTER_FLOOR = 'WHERE'
  public FILTER_BUTTON_CHANGE = 'CHANGE'
  public FILTER_BUTTON_DELETE = 'Delete'
  public FILTER_ALL_TYPES = 'All types'
  public FILTER_ALL_FLOORS = 'All floors'
  public FILTER_CURRENT_SELECTION = 'Search Artist'

  public TIMETABLE_NO_ENTRIES_HEADLNE = 'No shows found.'
  public TIMETABLE_NO_ENTRIES_MESSAGE =
    'Change the filters to see more shows here.'

  public FAVORITES_NO_ENTRIES_HEADLNE = 'No entries found'
  public FAVORITES_NO_ENTRIES_MESSAGE_DAY =
    'OOOooops… there are no bookmarks in your favorites for this day. Please have a look into our festival program.'
  public FAVORITES_NO_ENTRIES_MESSAGE =
    'OOOooops… there are no bookmarks in your favorites. Please have a look into our festival program.'

  public IMPORT_EXPORT_BTN = 'Import/Export'
  public INFO_CLOSE_BTN = 'Close Info'
  public SEARCH_BTN = 'Search'
  public SEARCH_RESULT = 'Search'
  public SEARCH_OK_BTN = 'OK'
  public SEARCH_CLOSE_BTN = 'Close'
  public SEARCH_PLACEHOLDER = 'Search program'
  public SEARCH_PLACEHOLDER_ARTIST = 'Search program'

  public FAVORITES_EXPORT_HEADLINE = 'Favorites Export'
  public FAVORITES_EMAIL_DESC =
    'Click the button to send an email with your favorites to your friends.'
  public FAVORITES_CLIPBOARD_DESC =
    'Or copy the code to the ckipboard and send it with an app of your choice'
  public FAVORITES_SHARE_DESC =
    'Share yout favorites with an app of your choice like Signal or Telegram.'
  public FAVORITES_EMAIL_BTN = 'Send with Email'
  public FAVORITES_CLIPBOARD_BTN = 'Copy to clipboard'
  public FAVORITES_SHARE_BTN = 'Share Code'

  public FAVORITES_SHARE_TITLE = 'Fusion Timetable'
  public FAVORITES_SHARE_TEXT = (link: string) =>
    `
I want to share my fusion favorites with you. You can import them into the app with the following link:
${link}

Manuell Import - use this only if the automatic import is not working. 
Open the app and navigate to Menu -> Favorites -> Import/Export.
Copy the text between START and ENDE into the "Import" - field inside the app.`
  public FAVORITES_SHARE_URL = 'https://timetable.fusion-festival.de'

  public FAVORITES_IMPORT_HEADLINE = 'Favorites Import'
  public FAVORITES_IMPORT_DESC =
    'Insert the import code from the mail or message here and click the button:'
  public FAVORITES_IMPORT_BTN = 'import'
  public FAVORITES_CLOSE_BTN = 'Close'

  public FAVORITES_COPY_CLIPBOARD_SUCCESS =
    'The code was copied into the clipboard. Paste the code in another app and send ot to your friends.'
  public FAVORITES_IMPORT_SUCCESS = 'Favorites were imported.'
  public FAVORITES_IMPORT_ERROR = 'Import error. Please check the input.'

  public RELOAD_APP_VERSION_INFO = 'Version info'
  public RELOAD_APP_CURRENT_VERSION = 'Current version:'
  public RELOAD_APP_LAST_UPDATE = 'Last Update:'
  public RELOAD_APP_RELOAD_HEADLINE = 'Hard Reload Button'
  public RELOAD_APP_DESC =
    'To update the app you have to close the browser tab or the app and then open again.'
  public RELOAD_APP_CONFIRMATION =
    'Click ok if you have internet and reload the app.'
  public RELOAD_APP_BTN = 'Reload APP'

  // Map
  public NEARBY = 'Nearby'
  public PLACES = 'Places'
  public LOCATION_DENIED =
    'You could not be located. You may need to allow your browser access to your location and reload the app.'

  // Departure
  public DEPARTURE_NO_INFO_HEADLINE = 'No infos available'
  public DEPARTURE_NO_INFO_MESSAGE =
    'Currently there are no infos available. Please check again later.'
  public DEPARTURE_LAST_UPDATE = 'last update: '

  public DEPARTURE_LOAD_ERROR =
    'Sorry, there is no internet connection. Please try again later.'
  public DEPARTURE_LOADING = 'loading data ...'
  public FONT_SIZE = 'Adjust font size'
  public ARTIST_NOTES = 'Your notes'
  public ARTIST_NOTES_SAVE = 'Save'
  public ARTIST_HIGHLIGHT = 'Highlight artist'
  public ARTIST_HIGHLIGHT_REMOVE = 'Remove artist highlight'
}

export default LanguageKeysEn
