import React from 'react'
import styled from 'styled-components'
import TimetableListContainer from '.'
import { colors } from '../components'
import { ListType } from './TimetableList'
import { BottomArea } from '../BottomArea/BottomArea'
import FilterSelects from '../Filter/FilterSelects'

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: ${colors.backgroundBlack};
`

const ContainerInner = styled.div`
  width: 100%;
  height: 100%;
  /* height: calc(100% - 52px); */
  display: flex;
`

type Props = {
  type?: ListType
}

type State = {
  leftScrollButton: boolean
  rightScrollButton: boolean
}

class SwitcherInner extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      leftScrollButton: false,
      rightScrollButton: false,
    }
  }

  // eslint-disable-next-line
  toggleScrollButtonLeft = (visible) => {
    this.setState({ leftScrollButton: visible })
  }

  render() {
    const { type } = this.props

    return (
      <Container>
        <ContainerInner>
          <TimetableListContainer type={type} />
        </ContainerInner>
        <FilterSelects />
        <BottomArea showFilterButton={type !== 'favorites'} />
      </Container>
    )
  }
}

const Switcher = ({ type }: { type?: ListType }) => {
  return <SwitcherInner type={type} />
}

export default Switcher
