import styled from 'styled-components'
import img from '../images/pattern.png'
import colors from './colors'

type Props = {
  alignLeft?: boolean
  withBackground?: boolean
  withSolidBackground?: boolean
  center?: boolean
}

export const Page = styled.div<Props>`
  display: flex;
  height: 100%;
  position: relative;
  /* height: 100vh; */

  flex-direction: column;
  color: #fff;
  text-align: ${(props: Props) => (props.alignLeft ? 'left' : 'center')};

  ${(props: Props) =>
    props.center
      ? `
  max-width: 1000px;
  margin: auto;
  `
      : null}

  ${(props: Props) =>
    props.withBackground
      ? `
    background-color: black;
    background-image: url(${img});
    background-repeat: repeat;
    background-size: 330px;
  `
      : props.withSolidBackground
      ? `background-color: ${colors.backgroundBlack}`
      : null}
`

export const Content = styled.div<{ padding?: string }>`
  flex: 1;
  padding: ${(props) => props.padding || '2rem 2rem 8rem'};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const SpecialContent = styled.div<{
  padding?: string
  variant?: 'primary' | 'secondary'
}>`
  background: ${(props) =>
    props.variant === 'secondary'
      ? colors.SpecialContent.bgSecondary
      : colors.SpecialContent.bg};
  border-radius: 1rem;
  padding: ${(props) => props.padding || '1.5rem'};
`

export const Bottom = styled.div<{ noPaddingBottom?: boolean }>`
  padding: 2rem;
  padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : '2rem')};
`

export const BottomDocked = styled.div<{ noPaddingBottom?: boolean }>`
  padding: 2rem;
  padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : '2rem')};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 20%,
    rgba(0, 0, 0, 0.99) 100%
  );
`

// export default Page
