import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  Body2,
  Col,
  Content,
  H3,
  H5,
  HSpacer,
  Message,
  Page,
  Row,
  Spacer,
  SpecialContent,
  Text,
  colors,
} from '../components'
import { Config } from '../const'
import { loadProperty, saveProperty } from '../helper/LocalStorage'
import { PlusButton } from '../TimetableList/PlusButton'

export const Overlay = styled.div<{ isVisible?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${colors.zIndex.menu};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'inherit' : 'none')};
  transition: opacity 0.3s ease-in-out;
`

const MenuItemInner = styled(H3)<{ isActive?: boolean; isFirst?: boolean }>`
  color: ${colors.white};
  padding: 1rem;
  color: ${(props) => (props.isActive ? colors.primary : colors.textInverted)};
  background-color: ${(props) => (props.isActive ? colors.white : 'none')};
  border-top: ${(props) => (props.isFirst ? 1 : 0)}px solid ${colors.white};
  border-bottom: 1px solid ${colors.white};
`

const MenuItem = ({
  to,
  title,
  onClose,
  isFirst,
}: {
  to: string
  title: ReactNode
  onClose: () => void
  isFirst?: boolean
}) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <MenuItemInner isActive={isActive} isFirst={isFirst} onClick={onClose}>
          {title}
        </MenuItemInner>
      )}
    </NavLink>
  )
}

type Props = {
  onClose: () => void
  isVisible?: boolean
}

let fontSize = loadProperty('fontSize') || 16
document.documentElement.style.fontSize = fontSize + 'px'
const sizeSteps = 2

export const Menu: FC<Props> = ({ onClose, isVisible }) => {
  return (
    <Overlay isVisible={isVisible}>
      <Page withBackground center>
        <Content padding="2rem 1rem">
          <Spacer size={1} />

          <MenuItem
            to="/timetable"
            title={<Message id="BUTTON_TIMETABLE" />}
            onClose={onClose}
            isFirst
          />

          <MenuItem
            to="/favorites"
            title={<Message id="BUTTON_FAVORITES" />}
            onClose={onClose}
          />
          {Config.REACT_APP_SHOW_MAP && (
            <>
              <MenuItem
                to="/map"
                title={<Message id="BUTTON_MAP" />}
                onClose={onClose}
              />
            </>
          )}
          {Config.REACT_APP_SHOW_DEPARTURE && (
            <MenuItem
              to="/departure"
              title={<Message id="BUTTON_DEPARTURE" />}
              onClose={onClose}
            />
          )}

          <Spacer size={3} />

          <SpecialContent>
            <H5>
              <Message id="EMERGENCY_HEADLINE_1" />{' '}
              <Message id="EMERGENCY_HEADLINE_2" />{' '}
              <Message id="EMERGENCY_HEADLINE_3" />
            </H5>

            <Text>
              <Message id="EMERGENCY_MESSAGE" />
            </Text>

            <H3>
              <a href="tel:0398332740400">
                <Message id="EMERGENCY_NUMBER" />
              </a>
            </H3>
          </SpecialContent>

          <Spacer size={3} />

          {/* <SpecialContent variant="secondary">
            <H5>
              <Message id="SPECIAL_POSTER_HEADLINE" />
            </H5>

            <Text>
              <Message id="SPECIAL_POSTER_TEXT" />
            </Text>
          </SpecialContent> */}

          <Spacer size={3} />

          <Row ml={4} mr={4}>
            <Col flex={1}>
              <Body2>
                <Message id="FONT_SIZE" />
              </Body2>
            </Col>
            <HSpacer size={1} />
            <PlusButton
              onClick={() => {
                fontSize += sizeSteps
                saveProperty('fontSize', fontSize)
                document.documentElement.style.fontSize = fontSize + 'px'
              }}
            />
            <HSpacer size={1} />
            <PlusButton
              variant="minus"
              onClick={() => {
                fontSize -= sizeSteps
                saveProperty('fontSize', fontSize)
                document.documentElement.style.fontSize = fontSize + 'px'
              }}
            />
          </Row>

          <Spacer size={6} />
        </Content>
      </Page>
    </Overlay>
  )
}
