import React, { FC, useRef } from 'react'
import styled from 'styled-components'
import colors from '../colors'
import { ReactComponent as Plus } from '../../images/plus.svg'

const InputContainer = styled.div<{ disabled?: boolean }>`
  background: ${colors.Search.background};
  border: 1px solid white;
  font-size: 14px;
  color: ${colors.Search.textColor};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const InputElement = styled.input`
  padding: 12px 1rem;
  margin: 0;
  flex: 1;
  background-color: transparent;
  border: none;
  color: ${colors.Search.textColor};
  font-size: 20px;
  font-weight: 600;
  min-width: 0;
  ::placeholder {
    color: ${colors.Search.textColor};
  }

  ::-webkit-input-placeholder {
    color: ${colors.Search.textColor};
  }

  &:focus::placeholder {
    opacity: 0;
  }
`

const CloseButton = styled.button<{ disabled?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  background: 'red';
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${colors.Search.closeIconBg};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  &:active {
    background-color: ${colors.highlight};
  }
  box-sizing: border-box;
  font-size: 0;
`

const CloseIcon = styled(Plus)`
  transform: rotate(45deg);
  fill: ${colors.Search.closeIconText}};
`
type Props = {
  onChange: (value: string) => void
  onEnter?: () => void
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
  value?: string
  className?: string
}

export const InputSearch: FC<Props> = (props) => {
  const {
    placeholder,
    disabled,
    value,
    className,
    autoFocus = true,
    onChange,
    onEnter,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  // eslint-disable-next-line
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onEnter?.()
      if (inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  return (
    <InputContainer disabled={disabled} className={className}>
      <InputElement
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        ref={inputRef}
        autoFocus={autoFocus}
      />
      <CloseButton disabled={!value} onClick={() => onChange('')}>
        <CloseIcon />
      </CloseButton>
    </InputContainer>
  )
}
