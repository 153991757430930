import React, { FC, Fragment, useCallback } from 'react'
import styled from 'styled-components'
import {
  Page,
  Content,
  BottomDocked,
  Row,
  Button,
  // H1,
  H2,
  // H3,
  H4,
  Text,
  Spacer,
  T,
  BreakLongWords,
  Col,
  colors,
  BubbleItem,
  SmallButton,
} from '../components'
import { getDayNameLong, getTimeString } from '../helper/DateFornat'
import { useLang } from '../state/language'
import { PlusButton } from '../TimetableList/PlusButton'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { closeFilter } from '../state/filter/filterSlice'
import { toggleFav } from '../state/timetable/timetableSlice'
import { getArtistWithShows } from '../TimetableList/selectors'
import { ArtistNotesAndHighlight } from './ArtistNotesAndHighlight'
import { useBackButton } from '../hooks/useBackButton'

const Buttons = styled.div`
  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`

const SShow = styled.div`
  margin-bottom: 1.5rem;
`

const PlayTimes = styled.div`
  background: ${colors.SpecialContent.bg};
  border-radius: 1rem;
  padding: 1.5rem;
`

const Divider = styled.div`
  border-bottom: 1px solid ${colors.SpecialContent.divider};
`

type Props = {
  id: string
}

const ArtistInfo: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch()

  const artist = useAppSelector((state) => getArtistWithShows(state, id))
  const lang = useLang()

  // console.log({ artist, info, notes })

  const close = useCallback(() => {
    dispatch(closeFilter())
  }, [dispatch])

  useBackButton({ onBackClick: close, enabled: true })

  if (!artist) {
    return null
  }

  const {
    artist: artistName,
    descDe,
    descEn,
    website,
    soundsample,
    shows,
  } = artist

  let text = lang === 'de' ? descDe : descEn

  // use other language text of own lang is not there
  if (!text) {
    text = lang === 'de' ? descEn : descDe
  }

  return (
    <Page withBackground={true} alignLeft center>
      <Content>
        <H2>
          <BreakLongWords>{artistName}</BreakLongWords>
        </H2>
        {artist.name && (
          <>
            <Spacer size={1.5} />
            <H4>
              <BreakLongWords>{artist.name}</BreakLongWords>
            </H4>
          </>
        )}
        <Spacer size={3} />
        <Text>{text}</Text>

        {(!!website || !!soundsample) && (
          <>
            <Spacer size={2} />
            <Buttons>
              {!!website && (
                <SmallButton
                  onClick={() => window.open(website, '_blank')}
                  mt={3}
                >
                  Homepage
                </SmallButton>
              )}

              {!!soundsample && (
                <SmallButton onClick={() => window.open(soundsample, '_blank')}>
                  Sound sample
                </SmallButton>
              )}
            </Buttons>
          </>
        )}

        {!!shows && shows.length > 1 && (
          <>
            <Spacer size={4} />
            <PlayTimes>
              <H4>
                <T id="ARTIST_INFO_PLAYTIME" />
              </H4>
              <Spacer size={1} />
              <Divider />
              <Spacer size={1} />
              {shows.map((show) => {
                const start = new Date(show.start)
                const end = new Date(show.end)
                return (
                  <Fragment key={show.showId}>
                    <SShow>
                      <Row>
                        <Col flex={1}>
                          <div>
                            <div>
                              <BubbleItem>{getDayNameLong(start)}</BubbleItem>
                            </div>
                            <div>
                              <BubbleItem>
                                {getTimeString(start)} - {getTimeString(end)}
                              </BubbleItem>
                            </div>
                            <div>
                              <BubbleItem>{show.floor}</BubbleItem>
                            </div>
                          </div>
                        </Col>

                        <PlusButton
                          $isFavorite={show.isFavorite}
                          $toggleColor
                          onClick={() => {
                            dispatch(toggleFav(show.showId))
                          }}
                        />
                      </Row>
                    </SShow>
                    <Spacer size={1} />
                    <Divider />
                    <Spacer size={1} />
                  </Fragment>
                )
              })}
            </PlayTimes>
          </>
        )}

        <Spacer size={2} />
        <ArtistNotesAndHighlight artist={artist} />
      </Content>
      <BottomDocked>
        <Row>
          <Button onClick={close}>
            <T id="INFO_CLOSE_BTN" />
          </Button>
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default ArtistInfo
