import React from 'react'
import styled from 'styled-components'
import { Row, Col } from '../components/Grid'
import colors from '../components/colors'
import { Body2, H5, HSpacer, Spacer } from '../components/Typo'
import { PlusButton, InfoButton } from './PlusButton'
import { ReactComponent as Star } from '../images/star.svg'

export type Act = {
  artist: string
  date: string
  day: string
  end: string
  floor: string
  from: string
  genre: string
  hasInfo: boolean
  id: number
  name: string
  showId: string
  start: string
  to: string
  isFavorite: boolean
  note?: string
  tag?: string
  tags?: string[]
}

const EntryContainer = styled.div<{ isFavorite: boolean; isLast?: boolean }>`
  margin: 0;
  padding: 1.5rem 0;
  transition: background-color 0.2s ease-out;
  background: ${(props) =>
    props.isFavorite ? colors.Entry.favorite.bg : colors.Entry.background};

  color: ${(props) =>
    props.isFavorite ? colors.Entry.favorite.text : colors.textInverted};

  border-bottom: ${(props) => (props.isLast ? 0 : 1)}px solid
    ${colors.Entry.border};
`

const LeftSide = styled.div`
  padding: 0rem 0 0rem 1rem;
`

const Time = styled(Body2)`
  margin-bottom: 0.1rem;
`

const Floor = styled(Body2)``

const Title = styled(H5)`
  padding: 0rem 1rem 0.5rem 1rem;
  flex: 1;
`

const TagContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Tag = styled(H5)`
  padding: 0;
  /* margin-right: 1rem; */
`

const TitleInner = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  border-spacing: 0;
`
const ShowTitle = styled(TitleInner)`
  font-family: 'Inter';
  font-size: 14px;
  margin-top: 0.5rem;
`

const PlusButtonArea = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row-reverse;
`

const FloorItem = styled.span`
  display: inline-block;
  margin-right: 1rem;
  &:before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid ${colors.Entry.floorItem};
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 0.5rem;
  }
`

type Props = {
  act: Act
  onClick: (showId: string) => void
  onInfoClick: () => void
  showTimeAndFloor: boolean
  isLast?: boolean
}

const Entry = ({
  act,
  onClick,
  onInfoClick,
  showTimeAndFloor,
  isLast,
}: Props) => (
  <EntryContainer isFavorite={act.isFavorite} isLast={isLast}>
    <Row alignItems="flex-start">
      <Col>
        <Title>
          <TitleInner>{act.artist}</TitleInner>
          {act.name && <ShowTitle>{act.name}</ShowTitle>}
        </Title>
        {act.tag && act.isFavorite && (
          <TagContainer>
            <Tag>
              <Star />
            </Tag>
          </TagContainer>
        )}
      </Col>
    </Row>
    <Spacer size={1} />
    <Row alignItems="center">
      <Col
        flexDirection="column"
        flex={1}
        onClick={() => {
          if (act.hasInfo) {
            onInfoClick()
          }
        }}
      >
        <LeftSide>
          {showTimeAndFloor && (
            <Time>
              {act.from} — {act.to} UHR
            </Time>
          )}

          <Floor>
            {showTimeAndFloor && <FloorItem>{act.floor}</FloorItem>}
            <FloorItem>{act.genre}</FloorItem>
          </Floor>
        </LeftSide>
      </Col>
      <Col>
        <PlusButtonArea>
          <PlusButton
            $isFavorite={act.isFavorite}
            onClick={() => {
              onClick(act.showId)
              // event.preventDefault()
            }}
          />
          {/* {act.hasInfo && ( */}
          <React.Fragment>
            <HSpacer size={1} />
            <InfoButton onClick={onInfoClick} />
          </React.Fragment>
          {/* )} */}
          {/* {act.tag && <H3 mr={3}>{act.tag}</H3>} */}
          {/* {act.tags?.map((tag) => (
            <H3 key={tag} mr={3}>
              {tag}
            </H3>
          ))} */}
        </PlusButtonArea>
      </Col>
    </Row>
  </EntryContainer>
)

export default Entry
