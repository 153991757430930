import { FC } from 'react'
import styled from 'styled-components'
import { Overlay } from '../Menu/Menu'
import { H5, Message, Page, Spacer, colors, pxToRem } from '../components'
import { PageTitle } from '../components/PageTitle'
import { Config } from '../const'
import { floorDescriptions, floors, types } from '../state/filter/consts'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import {
  changeFilter,
  getFilters,
  openFilter,
} from '../state/filter/filterSlice'
import { InfoButton } from '../TimetableList/PlusButton'

export const FilterButton = styled.button<{ isActive?: boolean }>`
  font-size: ${pxToRem(16)};
  /* padding: 10px 16px; */
  padding: 0 0.8rem;
  min-height: ${pxToRem(48)};
  color: ${colors.white};
  color: ${(props) => (props.isActive ? colors.text : colors.textInverted)};
  background-color: ${(props) =>
    props.isActive ? colors.white : colors.backgroundBlack};
  border: 1px solid ${colors.primary};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 2rem 1rem;
`

export const SectionTitle = styled(H5)`
  background-color: ${colors.Filters.titleBackground};
  padding: 1rem 0;
  text-align: center;
  border-bottom: 1px solid ${colors.Filters.border};
`

export const ScrollableContent = styled.div`
  /* flex: 1, */
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 5rem;
`

const InfoButtonContainer = styled.div`
  margin-left: ${pxToRem(12)};
`

type Props = {
  isVisible?: boolean
}

const showFloors = !Config.REACT_APP_SHOW_ARTISTS

export const FilterMenu: FC<Props> = ({ isVisible }) => {
  const filters = useAppSelector(getFilters)
  const dispatch = useAppDispatch()

  return (
    <Overlay isVisible={isVisible}>
      <Page withSolidBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_FILTER" />} />
        <ScrollableContent>
          <SectionTitle>
            <Message id="FILTER_GENRE" />
          </SectionTitle>

          <FilterContainer>
            {types.map((type) => (
              <FilterButton
                key={type}
                isActive={filters.types.includes(type)}
                onClick={() =>
                  dispatch(changeFilter({ filterId: 'types', value: type }))
                }
              >
                {type}
              </FilterButton>
            ))}
          </FilterContainer>

          {showFloors && (
            <>
              <Spacer size={2} />
              <SectionTitle>
                <Message id="FILTER_FLOORS" />
              </SectionTitle>
              <FilterContainer>
                {floors.map((floor) => {
                  const hasInfo = floorDescriptions.de[floor]
                  const isActive = filters.floors.includes(floor)
                  return (
                    <FilterButton
                      key={floor}
                      isActive={isActive}
                      onClick={() =>
                        dispatch(
                          changeFilter({ filterId: 'floors', value: floor }),
                        )
                      }
                    >
                      {floor}
                      {hasInfo && (
                        <InfoButtonContainer>
                          <InfoButton
                            $isFavorite={isActive}
                            $isWhite
                            $isSmall
                            onClick={(event) => {
                              event.stopPropagation()
                              event.preventDefault()
                              dispatch(
                                changeFilter({
                                  filterId: 'floorInfo',
                                  value: floor,
                                }),
                              )
                              dispatch(openFilter('floorInfo'))
                            }}
                          ></InfoButton>
                        </InfoButtonContainer>
                      )}
                    </FilterButton>
                  )
                })}
              </FilterContainer>
            </>
          )}
        </ScrollableContent>
      </Page>
    </Overlay>
  )
}
